import React from "react"
import {GatsbyImage} from "gatsby-plugin-image";

import remoteAssetUrl from "../../lib/remote-asset-url";

import ContentContainer from "../../components/content-container";

import styles from './component.module.less'

export default ({
  className,
  id,
  image,
  url,
  targetBlank,
}) => (
  <section id={id} className={`${className ? `${className} ` : ''}${styles.linkedImg}`}>
    <ContentContainer className={styles.cnt}>
      <a href={url} target={targetBlank ? '_blank' : undefined} className={styles.link}>
        <GatsbyImage className={styles.img} image={remoteAssetUrl(image.gatsbyImageData)} alt={image.alt} />
      </a>
    </ContentContainer>
  </section>
);
