import React from "react"

import ContentContainer from '../../components/content-container'
import Text from "../../components/text";
import Video from '../../components/video'

import styles from './component.module.less'

export default props => {

  const className = [styles.videoText];
  if (props.reverse === true) className.push(styles.reverse);
  if (props.className) className.push(props.className);

  return (
      <section id={props.id} className={className.join(' ')}>
        <ContentContainer className={styles.content}>
          {props.videoSrc &&
              <Video
                  posterSrc={props.posterSrc}
                  videoSrc={props.videoSrc}
                  locale={props.locale}
              />
          }
          {props.htmlText &&
              <Text
                  className={styles.text}
                  title={props.title}
                  htmlText={props.htmlText}
              />
          }
        </ContentContainer>
      </section>
  )
}
