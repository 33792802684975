import React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

import remoteAssetUrl from '../../lib/remote-asset-url'

import Button from '../../components/button'
import ContentContainer from '../../components/content-container'
import TextDecorator from '../../components/text-decorator'

import styles from './component.module.less'

export default function(props) {
  let classNames = [styles.cta];
  if (props.image) {
    classNames.push(styles.withImg);
  }
  if (props.className) {
    classNames.push(props.className);
  }
  classNames = classNames.join(' ');

  return (
      <section className={classNames}>
        <ContentContainer>
          <div className={styles.box}>
            {props.textDecorator &&
              <TextDecorator
                  className={styles.decorator}
                  widthOffset={-730}
              >
                {props.textDecorator}
              </TextDecorator>
            }

            <div className={styles.ctaContentBlock}>
                {props.headline &&
                    <div className={styles.headline} dangerouslySetInnerHTML={{__html: props.headline}} />
                }

                {props.text &&
                    <p className={styles.text}>{props.text}</p>
                }

                {props.buttonText && props.url &&
                    <Button href={props.url} theme="block">{props.buttonText}</Button>
                }
            </div>

            {props.image &&
              <GatsbyImage
                className={styles.imgCta}
                image={remoteAssetUrl(props.image.gatsbyImageData)}
                alt={props.image.alt}
              />
            }
          </div>
        </ContentContainer>
      </section>
  );
}
