import React from "react"

import Caption from '../../components/caption'
import ContentContainer from '../../components/content-container'
import Video from '../../components/video'

import styles from './component.module.less'

export default ({
  id,
  locale,
  className,
  posterSrc,
  videoSrc,
  title,
  caption,
}) => (
    <section
        id={id}
        className={`${styles.video}${className ? ` ${className}` : ''}`}
    >
      <ContentContainer className={styles.contentContainer}>

        <Video
            posterSrc={posterSrc}
            videoSrc={videoSrc}
            locale={locale}
        />

        {(title || caption) &&
            <Caption className={styles.caption}>
              {title && <span>{title}</span>}
              {caption && <em>{caption}</em>}
            </Caption>
        }

      </ContentContainer>
    </section>
)
