import React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

import remoteAssetUrl from '../../lib/remote-asset-url'

import Caption from '../../components/caption'
import ContentContainer from '../../components/content-container'

import styles from './component.module.less'

export default function(props) {

  if (props.src) {
    throw new Error(`image component want's gatsbyImageData`);
  }

  let classNames = [styles.img];
  if (props.className) {
    classNames.push(props.className);
  }
  if (props.border === undefined || props.border == null || props.border === true) {
    classNames.push(styles.border);
  }

  classNames = classNames.join(' ');

  return (
      <section id={props.id} className={classNames}>
        <ContentContainer className={styles.container}>
          {props.headline && (
            <div className={styles.headline} dangerouslySetInnerHTML={{__html: props.headline}} />
          )}

          <GatsbyImage className={styles.imgCnt} image={remoteAssetUrl(props.image.gatsbyImageData)} alt={props.alt} loading={props.loading} />

          {props.caption &&
            <Caption className={styles.caption}>
              <span>{props.title || props.alt}</span><em>{props.caption}</em>
            </Caption>
          }
        </ContentContainer>
      </section>
  );
}
