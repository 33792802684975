import React from 'react';
import { Helmet } from 'react-helmet';

const StructuredData = ({ type, ...data }) => {
  const d = {
    '@context': 'https://schema.org',
    '@type': type,
    ...data,
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(d)}</script>
    </Helmet>
  );
};

export default StructuredData;
