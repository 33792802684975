import React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

import remoteAssetUrl from '../../lib/remote-asset-url'

import Button from '../../components/button'
import Caption from '../../components/caption'
import ContentContainer from '../../components/content-container'
import Text from '../../components/text'

import styles from './component.module.less'

const renderContent = (props) => (
    <>
      {props.children && props.childPosition && props.childPosition === 'before' &&
        <>{props.children}</>
      }

      <div className={styles.inner}>

        {props.image &&
          <div className={styles.img}>
            {props.children && props.childPosition && props.childPosition === 'media' &&
              <div className={styles.childCap}>{props.children}</div>
            }

            <GatsbyImage
                className={styles.imgCnt}
                image={remoteAssetUrl(props.image.gatsbyImageData)}
                alt={props.image.alt}
                loading={props.imageLoading}
            />

            {(!props.captionButtonText || !props.captionButtonUrl) && props.caption && props.image.alt &&
                <Caption>
                  <span>{props.image.title || props.image.alt}</span><em>{props.caption}</em>
                </Caption>
            }

            {props.caption && props.captionButtonText && props.captionButtonUrl &&
              <div className={styles.btnCaption}>
                <em>{props.caption}</em>
                <Button theme="block" href={props.captionButtonUrl}>{props.captionButtonText}</Button>
              </div>
            }
          </div>
        }

        <div className={styles.txtContainer}>
          <Text
              title={props.title}
              headline={props.headline}
              headlineType={props.headlineType}
              dark={props.dark}
              header={props.header}
              className={styles.text}
              htmlText={props.htmlText}
          >{props.text}</Text>

          {props.children && props.childPosition && props.childPosition === 'text' &&
            <>{props.children}</>
          }
        </div>

        {props.image2 &&
          <div className={`${styles.img} ${styles.aside}`}>
            <GatsbyImage
                className={styles.imgCnt}
                image={remoteAssetUrl(props.image2.gatsbyImageData)}
                alt={props.image2.alt}
                loading={props.imageLoading}
            />
          </div>
        }

      </div>
    </>
);

export default function(props) {
  if (props.image && props.image.fluid) {
    throw new Error(`media-text "image" wan't an image with gatsbyImageData`);
  }

  if (props.image2 && props.image2.fluid) {
    throw new Error(`media-text "image2" wan't an image with gatsbyImageData`);
  }

  let classNames = [styles.mediaText];

  if (props.reverse && props.reverse === true) {
    classNames.push(styles.reverse);
  }
  if (props.image2) {
    classNames.push(styles.wide);
  }
  if (props.emphasizeMedia && props.emphasizeMedia === true) {
    classNames.push(styles.emMedia);
  }
  if (props.className) {
    classNames.push(props.className);
  }
  if (props.imageBorder === undefined || props.imageBorder == null || props.imageBorder === true) {
    classNames.push(styles.imgBorder);
  }
  if (props.image2 && (props.image2Border === undefined || props.image2Border == null || props.image2Border === true)) {
    classNames.push(styles.img2border);
  }
  if ((props.color)?.toLowerCase() === "grau") {
    classNames.push(styles.greyBg)
  }

  return (
      <section
          id={props.id}
          className={classNames.join(' ')}
          style={props.style}
      >
        {!props.image2 &&
          <ContentContainer className={styles.container}>
            {renderContent(props)}
          </ContentContainer>
        }

        {props.image2 &&
          <div className={styles.container}>
            {renderContent(props)}
          </div>
        }
      </section>
  );
}
