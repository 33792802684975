import React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from "gatsby"
import Color from "color"

import remoteAssetUrl from '../../lib/remote-asset-url'

import ContentContainer from '../../components/content-container'
import Text from '../../components/text'

import styles from './component.module.less'

const itemsPerRow = 4;

export default function(props) {

  const locale = props.locale || 'de';

  const isFull = props.layout === 'xs' ? false : (props.full && props.full === true);

  const items = (props.items || []).filter(n => n.name !== null);
  const rows = Math.ceil(items.length / itemsPerRow);

  let classNames = [styles.products];
  if (locale !== 'de') {
    classNames.push(styles[locale]);
  }
  if (isFull) {
    classNames.push(styles.full);
  }
  if (props.className) {
    classNames.push(props.className);
  }
  if (rows > 1) {
    classNames.push(styles.multiRow);
  }
  classNames = classNames.join(' ');

  return (
      <section id={props.id} className={classNames}>
        <ContentContainer>
          <Text
              center={true}
              title={props.title}
              headline={props.headline}
              htmlHeadline={props.htmlHeadline}
              titleClass={styles.title}
              headlineClassName={styles.headline}
          />

          <ol className={styles.items}>

            {items.map((product, i) => {
              const color = Color(product.color.hex);
              const backgroundGradStartColor = (color.luminosity() < 0.5 ? color.lighten(0.2) : color.darken(0.2)).desaturate(0.1);
              const backgroundGradEndColor = (color.luminosity() < 0.5 ? color.darken(0.25) : color.lighten(0.2));

              let itemStyle = {};

              if (isFull) {
                itemStyle.background = 'linear-gradient(180deg, ' + backgroundGradStartColor.hex() + ' 0%, ' + backgroundGradEndColor.hex() + ' 100%)';
              }

              let itemClasses = [styles.item];
              if (color.luminosity() < 0.5) {
                itemClasses.push(styles.dark);
              }
              itemClasses = itemClasses.join(' ');

              const url = `/${locale}${(props.urlPrefix ? '/' + props.urlPrefix : '')}/${product.slug.length > 0 ? product.slug + '/' : ''}`;

              return (
                  <li
                      key={i}
                      className={itemClasses}
                      style={itemStyle}
                  >
                    <Link to={url} state={{color: color.hex()}} className={styles.inner}>
                      {product.image &&
                        <GatsbyImage
                            className={styles.imgCnt}
                            as="span"
                            image={remoteAssetUrl(product.image.gatsbyImageData)}
                            alt={product.image.alt}
                        />
                      }
                      <span
                          className={styles.name}
                          style={
                            isFull ? {
                              background: 'linear-gradient(180deg, ' + color.hex() + '00 0%, ' + color.hex() + ' 66%)',
                            } : null
                          }
                      >
                        {product.name}
                        {product.subtitle && <span className={styles.subtitle}>{product.subtitle}</span>}
                      </span>
                    </Link>
                  </li>
              );
            })}
          </ol>

          {props.children}
        </ContentContainer>
      </section>
  );
}
