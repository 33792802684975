import React from "react"
import cheerio from "cheerio"

import ContentContainer from '../../components/content-container'
import Text from '../../components/text'

import styles from "./component.module.less"

export default ({ id, className, table, headline, text }) => {

  if (!table || table.length === 0) {
    return null;
  }

  const tableDom = cheerio.load(table);

  // thead

  const thead = tableDom('tbody tr:first-child');
  const theadCols = tableDom('td', thead[0]);

  const headers = Array.from(theadCols.map(i => {
    return tableDom(theadCols[i]);
  })).filter(h => h.text().trim().length > 0);

  const theadHtml =
      '<tr>' +
      Array.from(headers).reduce((carry, h) => {
        return carry + '<th>' + h.html() + '</th>';
      }, '') +
      '</tr>';

  // tbody

  const tbody = tableDom('tbody tr:not(:first-child)');

  const tbodyRows = tbody.map(rowIndex => {
    const cols = tableDom('td', tbody[rowIndex]);

    const colsHtml = cols.map(colIndex => {
      const name = headers[colIndex] ? headers[colIndex].text() : '';
      const col = tableDom(cols[colIndex]);
      return '<td data-label="' + name + '">' + col.html() + '</td>';
    });

    return Array.from(colsHtml).join('');
  });

  const tbodyHtml = '<tr>' + Array.from(tbodyRows).join('</tr><tr>') + '</tr>';

  return (
      <section id={id} className={`${styles.tableEl}${className ? ' ' + className : ''}`}>
        <ContentContainer>
          <div className={styles.content}>
            {(headline || text) && (
                <Text
                    className={styles.txt}
                    htmlHeadline={headline}
                    htmlText={text}
                />
            )}
            <table>
              {headers.length > 0 &&
                <thead dangerouslySetInnerHTML={{ __html: theadHtml }} />
              }
              <tbody dangerouslySetInnerHTML={{ __html: tbodyHtml }} />
            </table>
          </div>
        </ContentContainer>
      </section>
  )
}
