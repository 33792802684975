import React, {useState} from "react"

import Text from "../../components/text";
import ContentContainer from "../../components/content-container";
import StructuredData from "../../components/structured-data";

import styles from './component.module.less'

const FaqList = ({ className, headline, items = [] }) => {
  const [active, setActive] = useState([]);

  const toggleActive = (id) => {
    if (active.includes(id)) {
      setActive([...active.filter((actId) => actId !== id)]);
    } else {
      setActive([...active, id]);
    }
  }

  return (
    <>
      <section className={`${className ? `${className} ` : ''}${styles.faq}`}>
        <ContentContainer>
          {headline && (
            <Text htmlHeadline={headline} />
          )}

          <dl>
            {items.map(({ id, question, answer }) => (
              <React.Fragment key={id}>
                <dt>
                  <button onClick={() => toggleActive(id)}>{question}</button>
                </dt>
                {active.includes(id) && (
                  <dd dangerouslySetInnerHTML={{ __html: answer }} />
                )}
              </React.Fragment>
            ))}
          </dl>
        </ContentContainer>
      </section>

      <StructuredData
        type="FAQPage"
        {...{
          "@id": "#faq",
          "mainEntity": items.map(({ question, answer }) => ({
            "@type": "Question",
            name: question,
            acceptedAnswer: {
              "@type": "Answer",
              text: answer,
            },
          })),
        }}
      />
    </>
  );
}

export default FaqList;
