import React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

import remoteAssetUrl from '../../lib/remote-asset-url'

import MoreLink from '../../components/more-link'

import styles from './component.module.less'

export default function({ locale = 'de', data = {}, urlPrefix = '/', className }) {
  const { slug, image, title, teasertext } = data;

  let classNames = [styles.item];
  if (locale !== 'de') {
    classNames.push(styles[locale]);
  }
  if (className) {
    classNames.push(className);
  }
  classNames = classNames.join(' ');

  return (
      <MoreLink
          locale={locale}
          className={classNames}
          href={`${urlPrefix}${slug}/`}
      >
        <GatsbyImage
            as="span"
            className={styles.imgCnt}
            width={654}
            height={464}
            image={remoteAssetUrl(image?.gatsbyImageData)} alt={image.alt}
        />
        <span className={styles.desc}>
          <span className={styles.title}>{title}</span>
          <span className={styles.txt}>{teasertext}</span>
        </span>
      </MoreLink>
  )
}
