import React from "react"

import Text from '../../components/text'

import styles from './component.module.less'
import remoteAssetUrl from "../../lib/remote-asset-url";
import {GatsbyImage} from "gatsby-plugin-image";
import ContentContainer from "../../components/content-container";

export default (props) => {
    let classNames = []
    if(props.splitBackground) {
        classNames.push(styles.splitBg)
    }
    const mediaTextCompositionClassName = classNames.join(" ")

    return (
        <section id={props.id} className={`${styles.mediaTextComposition} ${mediaTextCompositionClassName} ${props.className ? ' ' + props.className : ''}`}>
            <ContentContainer className={styles.mediaTextCompositionContent}>
                <div className={styles.mediaTextCompositionBlock}>
                    <div className={styles.mediaTextCompositionText}>
                        <Text
                            title={props.title}
                            headline={props.headline}
                            htmlHeadline={props.htmlHeadline}
                            htmlText={props.text}
                        />
                    </div>
                    <div className={styles.imageGallery}>
                        <div className={styles.imageContainer}>
                            {props.imageGallery.map(image => {
                                return<div className={styles.imageBlock}>
                                    <GatsbyImage
                                        image={remoteAssetUrl(image.gatsbyImageData)}
                                        alt={image.alt}
                                        title={image.title}
                                        className={styles.mediaTextCompositionImage}
                                    />
                                    <div className={styles.imageTitle}>{image.title}</div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
            </ContentContainer>

        </section>
    );
}
