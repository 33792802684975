import React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

import remoteAssetUrl from '../../lib/remote-asset-url'

import Button from '../../components/button'
import ContentContainer from '../../components/content-container'
import TextDecorator from '../../components/text-decorator'
import Text from '../../components/text'

import styles from './component.module.less'

export default function(props) {

  const features = (props.features || []).filter(f => f && f.length > 0);

  return (
      <section id={props.id} className={`${styles.appTeaser}${props.className ? ' ' + props.className : ''}`}>
        <ContentContainer className={styles.container}>
          {props.textDecorator &&
            <TextDecorator>{props.textDecorator}</TextDecorator>
          }

          <Text
              className={styles.txt}
              center={true}
              title={props.title}
              headline={props.headline}
              htmlHeadline={props.htmlHeadline}
          />

          <div className={styles.scrContainer}>
            <div className={styles.screenshot}>
              <GatsbyImage className={styles.imgCnt} image={remoteAssetUrl(props.screenshot.gatsbyImageData)} alt={props.screenshot.alt} />
            </div>

            <ol className={styles.features}>
              {features.map((f, i) => (
                  <li key={i}>{f}</li>
              ))}
            </ol>
          </div>

          <div className={styles.btns}>
            {props.appleUrl &&
                <Button href={props.appleUrl} target="_blank" rel="noreferrer">Apple App Store Download</Button>
            }
            {props.googleUrl &&
                <Button href={props.googleUrl} target="_blank" rel="noreferrer">Google Play Store Download</Button>
            }
          </div>

        </ContentContainer>
      </section>
  );
}
