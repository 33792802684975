import React from "react"

import Text from '../../components/text'

import styles from './component.module.less'

import Card from "../../components/card";
import ContentContainer from "../../components/content-container";
import {GatsbyImage} from "gatsby-plugin-image";
import remoteAssetUrl from "../../lib/remote-asset-url";

const LayoutOne = "Layout1";
const LayoutTwo = "Layout2"

export default (props) => {
    return (
        <section id={props.id} className={props.className}>
            <ContentContainer className={styles.feature}>
                {props.layouts === LayoutTwo &&
                    <div className={styles.iconBlock}>
                        {props.icon &&
                            <GatsbyImage
                                image={remoteAssetUrl(props.icon.gatsbyImageData)}
                                alt={props.alt}
                            />
                        }
                    </div>
                }
                <Text
                    className={props.layouts === LayoutTwo ? styles.featureTextBlock : ""}
                    headline={props.headline}
                    headlineClassName={props.layouts === LayoutTwo ? styles.layoutTwoHeadline : ""}
                />

                {props.layouts === LayoutOne &&
                    <div className={styles.layoutOne}>
                        {props.imageText.map(card => {
                            return<Card
                                image={card.image.gatsbyImageData}
                                text={card.text}
                            />
                        })}
                    </div>

                }

                {props.layouts === LayoutTwo &&
                    <div className={styles.layoutTwo}>
                        {props.imageText.map(card => {
                            return<Card
                                image={card.image.gatsbyImageData}
                                text={card.text}
                                className={styles.featureCard}
                            />
                        })}
                    </div>

                }

            </ContentContainer>
        </section>
    );
}
