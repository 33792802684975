import React from 'react'

import Slider from '../../components/slider'

import AdvisorItem from  '../../components/advisor-item'

export default ({ locale, id, className, headline, urlPrefix, items }) => (
    <Slider
        locale={locale}
        id={id}
        className={className}
    >
      {{
        headline,
        items: items.map((item, i) => (
            <AdvisorItem
                key={i}
                locale={locale}
                data={item}
                urlPrefix={urlPrefix}
            />
        ))
      }}
    </Slider>
);
