import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import remoteAssetUrl from '../../lib/remote-asset-url'

import Slider from '../../components/slider'
import MoreLink from '../../components/more-link'

import styles from './component.module.less'

export default ({ locale, id, className, headline, elements }) => (
    <Slider
        locale={locale}
        id={id}
        className={`${styles.teaserSlider}${className ? ` ${className}` : ''}`}
        autoplay={true}
        loop={true}
        autoplaySpeed={ 6000 }
    >
      {{
        headline: (
            <div dangerouslySetInnerHTML={{ __html: headline }} />
        ),
        items: elements.map(({ url, image, textOnTop, textBelow }, i) => (
            <MoreLink
                key={i}
                locale={locale}
                className={styles.item}
                href={url}
            >
                <span className={styles.wrap}>
                    <GatsbyImage
                        as="span"
                        className={styles.img}
                        width={654}
                        height={464}
                        image={remoteAssetUrl(image.gatsbyImageData)} alt={image.alt}
                    />
                    {textOnTop &&
                        <span className={styles.desc}>
                            <span className={styles.txt}>{textOnTop}</span>
                        </span>
                    }
                </span>
                {textBelow &&
                    <span className={styles.descBl}>
                        <span className={styles.txt}>{textBelow}</span>
                    </span>
                }
            </MoreLink>
        ))
      }}
    </Slider>
);
