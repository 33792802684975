import React, { useState, useRef } from "react"

import Text from '../../components/text'

import styles from './component.module.less'
import {GatsbyImage} from "gatsby-plugin-image";
import remoteAssetUrl from "../../lib/remote-asset-url";
import Button from "../button";

export default function (props) {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);

    const toggleDiv = () => {
        setIsOpen(!isOpen);
    };


    const getHeight = () => {
        return isOpen ? contentRef.current.scrollHeight + 'px' : '0';
    };

    return (
        <div className={`${props.className ? `${props.className} ` : ''}${styles.savingPlan}`}>
            <GatsbyImage
                image={remoteAssetUrl(props.image)}
                alt={props.alt}
                className={styles.savingPlanImage}
                imgClassName={styles.savingPlanImg}
            />
            <div className={styles.savingPlanContent}>
                <Text headline={props.name} headlineClassName={styles.savingPlanHeadline} className={styles.savingPlanName}/>
                <div className={`${styles.savingPlanContentToggle}`}>
                    <div dangerouslySetInnerHTML={{__html: props.intro}} />
                    { props.description && (
                        <div  style={{ height: getHeight() }} ref={contentRef}
                              className={`${styles.description} ${isOpen ?  styles.open : styles.close}`}
                             dangerouslySetInnerHTML={{__html: props.description}} />
                    )}
                </div>
                <Button className={`${styles.savingPlanButton} 
                        ${isOpen ? styles.savingPlanLess : styles.savingPlanMore}`}
                        onClick={toggleDiv}>
                    {isOpen? "weniger erfahren" : "mehr erfahren"}
                </Button>
            </div>
        </div>
    );
}
