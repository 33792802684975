import React from "react"
import {graphql, StaticQuery} from "gatsby"

import ContentContainer from '../../components/content-container'
import TextDecorator from '../../components/text-decorator'

import NewsletterForm from '../../forms/newsletter'

import styles from './component.module.less'

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                allDatoCmsConfig {
                    nodes {
                        locale
                        pageDataProtection {
                            slug
                        }
                    }
                }
                allDatoCmsTemplateTexte {
                    nodes {
                        locale
                        formNewsletterTxt
                        formNewsletterPrivacy
                    }
                }
            }
        `}
        render={data => {
          const config = data.allDatoCmsConfig.nodes.find(t => t.locale === (props.locale || 'de'));
          const templateTxt = data.allDatoCmsTemplateTexte.nodes.find(t => t.locale === (props.locale || 'de'));

          const privacyTxtParts = templateTxt.formNewsletterPrivacy.split('%');
          const privacyUrl = '/' + (props.locale || 'de') + '/' + config.pageDataProtection.slug + '/';

          let text = null;
          if (props.useDefaultText && props.useDefaultText === true && templateTxt) {
            text = templateTxt.formNewsletterTxt;
          }
          if (!props.useDefaultText) {
            text = props.text;
          }

          return (
              <section
                  id={props.id}
                  className={`${styles.nlStandalone}${props.className ? ' ' + props.className : ''}`}
              >
                <ContentContainer className={styles.inner}>
                  <TextDecorator className={styles.deco}>Newsletter</TextDecorator>

                  <div className={styles.content}>
                    {text &&
                      <p className={styles.txt}>{text}</p>
                    }
                    <div className={styles.form}>
                      <NewsletterForm
                          className={styles.inner}
                          locale={props.locale}
                          theme="light"
                          hideErrorMessages={true}
                      />
                      <p className={styles.privacy}>
                        {privacyTxtParts.map((t, j) => {
                          if (j % 2 === 0) return (<span key={j}>{t}</span>);
                          return (<a href={privacyUrl} target="_blank" rel="noreferrer" key={j}>{t}</a>);
                        })}
                      </p>
                    </div>

                  </div>
                </ContentContainer>
              </section>
          );
        }}
    />
)
