import React, {useState} from "react"
import {graphql, StaticQuery} from "gatsby";

import remoteAssetUrl from "../../lib/remote-asset-url";

import styles from "./component.module.less";

export default props => {

    const locale = props.locale || 'de';

    const [play, setPlay] = useState(false);

    return (
        <StaticQuery
            query={graphql`
                    query {
                        allDatoCmsTemplateTexte {
                            nodes {
                                locale
                                videoPlayButtonLabel
                            }
                        }
                    }
                `}
            render={data => {
                const templateStrings = data.allDatoCmsTemplateTexte.nodes.find(t => t.locale === locale);

                return (
                    <div
                        className={`${styles.video}${play ? ' ' + styles.playing : ''}${props.className ? ' ' + props.className : ''}`}
                        style={{
                            backgroundImage: 'url(' + remoteAssetUrl(props.posterSrc) + ')'
                        }}
                    >
                        {play &&
                            <>
                                {/* eslint-disable jsx-a11y/media-has-caption */}
                                <video
                                    controls={true}
                                    autoPlay={true}
                                    data-setup="{}"
                                >
                                    <source src={remoteAssetUrl(props.videoSrc)} type="video/mp4" />
                                </video>
                                {/* eslint-enable jsx-a11y/media-has-caption */}
                            </>
                        }

                        <div className={styles.overlay}>
                            <button onClick={() => setPlay(true)}>{templateStrings.videoPlayButtonLabel}</button>
                        </div>
                    </div>
                );
            }}
        />
    )
}
