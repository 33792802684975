import React, {useEffect, useState} from 'react'
import {graphql, StaticQuery} from 'gatsby'

import ContentContainer from '../content-container'

import styles from './component.module.less'

const swipeThreshold = 80;

export default ({ id, className, children, locale = 'de', autoplay = false, autoplaySpeed = 3000, loop = false }) => {

  const {headline, items} = (children || {});
  if (!items) return null;

  const itemCount = items.length;
  let itemWidthPercentage = Math.floor(10000 / itemCount) / 100;

  const [index, setIndex] = useState(0);
  const [swipeStartX, setSwipeStartX] = useState(0);
  const [autoplayStop, setAutoplayStop] = useState(false);

  const onPrevClick = () => {
    setAutoplayStop(true);

    if (loop === true) {
      if (index === 0) {
        setIndex(itemCount - 1);
        return;
      }
    } else {
      if (index === 0) {
        return;
      }
    }

    setIndex(index - 1);
  };

  const onNextClick = () => {
    setAutoplayStop(true);
    nextSlide();
  };

  const nextSlide = () => {
    setIndex((prevIdx) => {
      if (loop === true) {
        if (prevIdx >= itemCount - 1) {
          return 0;
        }
      } else {
        if (prevIdx >= itemCount - 1) {
          return prevIdx;
        }
      }

      return prevIdx + 1;
    });
  };

  const onTouchStart = (e) => setSwipeStartX(e.changedTouches[0].clientX);

  const onTouchEnd = (e) => {
    const swipeDelta = swipeStartX - e.changedTouches[0].clientX;
    if (Math.abs(swipeDelta) < swipeThreshold) return;
    if (swipeDelta > 0) {
      onNextClick();
    } else {
      onPrevClick();
    }
  };

  // autoplay
  useEffect(() => {
    if (!autoplay || autoplayStop === true) {
      return;
    }

    const int = setInterval(() => {
      if (autoplayStop === true) return;
      nextSlide();
    }, autoplaySpeed);

    return () => clearInterval(int);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoplay, autoplayStop, autoplaySpeed]);

  return (
      <StaticQuery
        query={query}
        render={(data) => {
          const { pagerPrevLabel, pagerNextLabel } =
              data.allDatoCmsTemplateTexte.nodes.find(t => t.locale === locale);

          return (
            <section
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
                id={id}
                className={`${styles.slider}${className ? ' ' + className : ''}`}
            >
              <ContentContainer>
                <header>
                  {headline &&
                    <div className={styles.headline}>{headline}</div>
                  }

                  {itemCount > 1 && (
                    <>
                      <button onClick={onPrevClick} disabled={!loop && index === 0} className={`${styles.pager}`}>
                        {pagerPrevLabel}
                      </button>

                      <button onClick={onNextClick} disabled={!loop && index >= itemCount - 1} className={`${styles.pager}`}>
                        {pagerNextLabel}
                      </button>
                    </>
                  )}
                </header>

                <ol className={styles.list} style={{
                  transform: 'translateX(' + (index * itemWidthPercentage) * -1 + '%)'
                }}>
                  {items.map((item, i) => (
                    <li
                      key={i}
                      style={{
                        width: itemWidthPercentage + '%',
                      }}
                    >
                      {item}
                    </li>
                  ))}
                </ol>
              </ContentContainer>
            </section>
          )
        }}
      />
  )
}

const query = graphql`
    query {
        allDatoCmsTemplateTexte {
            nodes {
                locale
                pagerPrevLabel
                pagerNextLabel
            }
        }
    }
`
