import React from "react"

import Text from  '../../components/text'

import CtaBig from '../cta-big'

import styles from './component.module.less'

export default (props) => {

  const rows = (props.rows || []).filter(r => r.text !== undefined && r.text !== null && r.text.length > 0);

  return (
      <CtaBig
          id={props.id}
          className={props.className}
          decoration={props.decoration}
          title={props.title}
          headline={props.headline}
          htmlHeadline={props.htmlHeadline}
          text={props.text}
          buttonText={props.buttonText}
          buttonUrl={props.buttonUrl}
      >
        {rows.length > 0 &&
          <div className={styles.boxes}>
            {rows.map((row, i) => (
                <div key={i} className={styles.box}>
                  <Text
                      title={row.title}
                      htmlText={row.text}
                  />
                </div>
            ))}
          </div>
        }
      </CtaBig>
  );
}
