import React from "react"

import Text from '../../components/text'
import TextDecorator from '../../components/text-decorator'

import styles from './component.module.less'
import remoteAssetUrl from "../../lib/remote-asset-url";
import {GatsbyImage} from "gatsby-plugin-image";

export default (props) => {
    let classNames = [styles.ctaBigNew];

    if (props.className) {
        classNames.push(props.className);
    }

    if (props.children && React.Children.count(props.children) > 0) {
        classNames.push(styles.withCntnt);
    }

    classNames = classNames.join(' ');
    return (
        <section id={props.id} className={classNames}>
            {props.decoration &&
                <TextDecorator
                    className={styles.decorator}
                    color1="#C09A5D"
                    color2="rgba(255, 255, 255, .31)"
                    widthBase="85vw"
                >{props.decoration}</TextDecorator>
            }
            <div className={styles.ctaContent}>
                <div className={styles.container}>
                    <Text
                        header={true}
                        dark={true}
                        title={props.title}
                        headline={props.headline}
                        htmlHeadline={props.htmlHeadline}
                        htmlText={props.text}
                        headlineClassName={styles.headline}
                    />
                </div>

                <div className={styles.ctaNewButton}>
                    {props.buttonText && props.buttonUrl &&
                        <a className={styles.ctaNew} href={props.buttonUrl}><span>{props.buttonText}</span></a>
                    }
                </div>

                {props.children}
            </div>
            {props.image &&
                <GatsbyImage
                    className={styles.imgCtaBig}
                    image={remoteAssetUrl(props.image.gatsbyImageData)}
                    alt={props.alt}
                />
            }
        </section>
    );
}
