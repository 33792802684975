import React from "react"

import styles from './component.module.less'
import {GatsbyImage} from "gatsby-plugin-image";
import remoteAssetUrl from "../../lib/remote-asset-url";

export default function (props) {

    return (
        <div id={props.id} className={`${props.className ? '' + props.className : ''} ${styles.card}`}>
            {props.image &&
                <GatsbyImage
                    image={remoteAssetUrl(props.image)}
                    alt={props.alt}
                />
            }
            {props.text &&
                <div className={styles.cardText} dangerouslySetInnerHTML={{__html: props.text}}></div>
            }
        </div>
    );
}
