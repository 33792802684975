import React from 'react'
import {Link} from 'gatsby';

import styles from './component.module.less'

export default ({ locale = 'de', className, children, href = '' }) => (
    <>
        {href.startsWith('/') && (
            <Link to={href} className={`${styles.more} ${locale}${className ? ` ${className}` : ''}`}>
                {children}
            </Link>
        )}

        {!href.startsWith('/') && (
            <a href={href} className={`${styles.more} ${locale}${className ? ` ${className}` : ''}`}>
                {children}
            </a>
        )}
    </>
)
