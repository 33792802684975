import React from "react"

import Text from '../../components/text'

import styles from './component.module.less'
import remoteAssetUrl from "../../lib/remote-asset-url";
import {GatsbyImage} from "gatsby-plugin-image";
import ContentContainer from "../../components/content-container";

export default (props) => {
    return (
        <section id={props.id} className={`${props.className} ${styles.imageTextSection}`}>
            <ContentContainer>
                <div className={`${styles.ctaContent } ${props.color.toLowerCase() === "beige" ? styles.imageTextWhite : ""} ${props.alignment === "links" ? styles.reverse : ""}`}>
                    <Text
                        title={props.title}
                        headline={props.headline}
                        headlineClassName={styles.imageTextHeadlineClass}
                        htmlHeadline={props.htmlHeadline}
                        htmlText={props.text}
                        className={styles.imageTextContent}
                    />
                    {props.image &&
                        <GatsbyImage
                            image={remoteAssetUrl(props.image.gatsbyImageData)}
                            alt={props.alt}
                            className={styles.imageTextImg}
                        />
                    }
                </div>
            </ContentContainer>
        </section>
    );
}
