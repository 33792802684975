import React from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

import id from "../../lib/id"
import remoteAssetUrl from "../../lib/remote-asset-url"

import Button from "../button"
import Caption from "../caption"
import VisibilityContainer from "../visibility-container"

import AppTeaser from "../../content-elements/app-teaser"
import AdvisorSlider from "../../content-elements/advisor-slider"
import Benefits from "../../content-elements/benefits"
import BenefitsContainer from "../../content-elements/benefits-container"
import Cta from "../../content-elements/cta"
import CtaBig from "../../content-elements/cta-big"
import GlobalElement from "../../content-elements/global-element"
import TextFormated from "../../content-elements/text-formated"
import Image from "../../content-elements/image"
import Video from "../../content-elements/video"
import VideoText from "../../content-elements/video-text"
import Plans from "../../content-elements/plans"
import QuotePersonal from "../../content-elements/quote-personal"
import PriceChart from "../../content-elements/price-chart"
import PriceTable from "../../content-elements/price-table"
import PriceBox from "../../content-elements/price-box"
import Products from "../../content-elements/products"
import Quote from "../../content-elements/quote"
import MediaText from "../../content-elements/media-text"
import NewsletterStandalone from "../../content-elements/newsletter-standalone"
import Search from "../../content-elements/search"
import SavingsPlanFinder from "../../content-elements/savings-plan-finder"
import MediaIndex from "../../content-elements/media-index"
import TeaserSlider from "../../content-elements/teaser-slider"
import Table from "../../content-elements/table"
import Feature from "../../content-elements/feature"
import CtaBigNew from "../../content-elements/cta-big-new"
import FaqList from "../../content-elements/FaqList";
import ImageText from "../../content-elements/image-text"
import MediaTextComposition from "../../content-elements/media-text-composition"
import SavingPlanOverview from "../../content-elements/saving-plan-overview";


import styles from "./component.module.less"
import LinkedImage from "../../content-elements/linked-image";

export default props => {

  const items = props.items || []
  const locale = props.locale || "de"
  const config = props.config || {}
  const elementClassNames = props.classNames || {}
  const elementMutators = props.mutators || {}

  return (
    <>
      <>{props.children}</>

      {items.map((ce, i) => {
        const itemType =
          (ce && ce.internal && ce.internal.type) || "__unknown__"

        // mutate element

        if (elementMutators[itemType]) {
          ce = elementMutators[itemType](ce, i)
        }

        // id attribute

        const ceId =
            (ce && (ce.anchorName !== undefined || ce.ankerName !== undefined)) &&
            ((ce.anchorName && ce.anchorName.length > 0) || (ce.ankerName && ce.ankerName.length > 0))
                ? (id(ce.anchorName) || id(ce.ankerName))
                : null;

        // class attribute

        let classNames = []
        if (ce && (ce.color || "").toLowerCase() === "beige") {
          classNames.push(styles.em)
        }
        if (elementClassNames[itemType]) {
          const ecn = elementClassNames[itemType](ce, i);
          if (ecn) {
            classNames.push(elementClassNames[itemType](ce, i))
          }
        }

        const className = classNames.join(" ")

        // render

        switch ((ce && ce.internal && ce.internal.type) || null) {
          case "DatoCmsText":
          case "DatoCmsSimpletext":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <TextFormated
                    id={ceId}
                    className={className}
                    html={ce.text}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsImage":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <Image
                    id={ceId}
                    className={className}
                    image={ce.src}
                    alt={ce.src.alt}
                    title={ce.src.title}
                    caption={ce.caption}
                    border={ce.imageBorder}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsSimpleimage":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <Image
                    id={ceId}
                    className={className}
                    headline={ce.headline}
                    image={ce.image}
                    alt={ce.image.alt}
                    caption={ce.image.title}
                    border={ce.imageBorder}
                    layoutRaise={ce.layoutRaise === true}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsAdvisorTeaser":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <AdvisorSlider
                    id={ceId}
                    className={className}
                    locale={locale}
                    urlPrefix={"/" + locale + "/" + config.pageAdvisor.slug + "/"}
                    headline={ce.headline}
                    items={ce.items.filter(item => item.slug !== null)}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsSearch":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <Search
                    id={ceId}
                    locale={locale}
                    className={className}
                    titleHtml={ce.title}
                    placeholder={ce.placeholder}
                    tags={ce.links.map(link => {
                      return {
                        name: link.name,
                        url: "/" + locale + "/" + link.slug + "/",
                      }
                    })}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsBenefit":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <Benefits
                    id={ceId}
                    className={className}
                    items={[ce.first, ce.second, ce.third]}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsSingleVideo":
          case "DatoCmsVideo":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <Video
                    id={ceId}
                    className={className}
                    videoSrc={ce.src.url}
                    posterSrc={ce.preview ? ce.preview.fluid.src : null}
                    title={ce.src.title}
                    caption={ce.src.alt}
                    layoutRaise={ce.layoutRaise === true}
                    locale={locale}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsPlansTeaser":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <Plans
                    id={ceId}
                    className={className}
                    decoration={ce.decoration}
                    title={ce.title}
                    htmlHeadline={ce.headline}
                    text={ce.text}
                    buttonText={ce.buttonText}
                    buttonUrl={ce.buttonUrl}
                    rows={[
                      {
                        title: ce.row1Title,
                        text: ce.row1Text,
                      },
                      {
                        title: ce.row2Title,
                        text: ce.row2Text,
                      },
                    ]}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsCallToAction":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <Cta
                    id={ceId}
                    className={className}
                    buttonText={ce.buttonText}
                    url={ce.buttonUrl}
                    headline={ce.headline}
                    text={ce.text}
                    textDecorator={ce.textDecorator}
                    image={ce.image}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsCallToActionTextButton":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <CtaBig
                    id={ceId}
                    className={className}
                    decoration={ce.decoration}
                    title={ce.title}
                    htmlHeadline={ce.headline}
                    text={ce.text}
                    buttonText={ce.buttonText}
                    buttonUrl={ce.buttonUrl}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsPlanFinder":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <SavingsPlanFinder
                    id={ceId}
                    className={className}
                    locale={locale}
                    htmlHeadline={ce.headline}
                    buttonText={ce.buttonText}
                    image={ce.image}
                    sidebarImage={ce.sidebarImage}
                    sidebarHeadline={ce.sidebarHeadline}
                    sidebarHtmlText={ce.sidebarText}
                    sidebarButtonText={ce.sidebarButtonText}
                    sidebarButtonUrl={ce.sidebarButtonUrl}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsTestimonial":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <QuotePersonal
                    id={ceId}
                    quotes={[
                      {
                        text: ce.quote1,
                        person: {
                          name: ce.name1,
                          image: ce.image1,
                        },
                      },
                      {
                        text: ce.quote2,
                        person: {
                          name: ce.name2,
                          image: ce.image2,
                        },
                      },
                    ]}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsQuote":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <Quote id={ceId} className={className}>
                    {ce.text}
                  </Quote>
                </VisibilityContainer>
            )
          case "DatoCmsProductlist":
            const productListItems = ce.products.map((item) => {
              switch (item.__typename) {
                case 'DatoCmsProdukt':
                  return {
                    name: item.name,
                    subtitle: item.nameSubtitle,
                    image: item.image,
                    slug: item.slug,
                    color: item.color,
                  };
                case 'DatoCmsSeiten':
                  return {
                    name: item.name,
                    subtitle: item.teaserNameSubtitle,
                    image: item.teaserImage,
                    slug: item.slug === 'index' ? '' : item.slug,
                    color: {
                      hex: '#957D6A',
                    },
                  };
                default:
                  return null;
              }
            }).filter(p => p != null);

            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <Products
                    id={ceId}
                    className={className}
                    urlPrefix={config.productUrlPrefix}
                    full={ce.full === undefined ? true : ce.full}
                    layout={ce.layout}
                    locale={locale}
                    title={ce.title}
                    htmlHeadline={ce.headline}
                    items={productListItems}
                  >
                    {(ce.sealQuote || (ce.seal && ce.seal.gatsbyImageData)) && (
                      <Quote
                        className={styles.info}
                        containerClassName={styles.infoContainer}
                        standalone={true}
                      >
                        {ce.seal && ce.seal.gatsbyImageData &&
                            <span>
                              <GatsbyImage
                                  as="span"
                                  image={remoteAssetUrl(ce.seal.gatsbyImageData)}
                                  alt={ce.seal.alt}
                                  style={{width: '140px'}}
                              />
                            </span>
                        }
                        {ce.sealQuote &&
                            <span>{ce.sealQuote}</span>
                        }
                      </Quote>
                    )}
                  </Products>
                </VisibilityContainer>
            )
          case "DatoCmsTeaserQuarter":
            let items = [
              {
                name: ce.col1Title,
                image: ce.col1Image,
                slug: ce.col1Link.slug,
                color: ce.col1Product.color,
              },
            ]

            const attribs = ["Title", "Image", "Link", "Product"]

            for (let i = 2; i <= 4; i++) {
              const values = attribs
                .map(a => ce["col" + i + a])
                .filter(v => v !== null)

              if (values.length !== attribs.length) {
                continue
              }

              items.push({
                name: values[0],
                image: values[1],
                slug: values[2].slug,
                color: values[3].color,
              })
            }

            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <Products
                    id={ceId}
                    className={className}
                    full={true}
                    locale={locale}
                    title={ce.title}
                    htmlHeadline={ce.headline}
                    items={items}
                  >
                    {(ce.sealQuote || (ce.seal && ce.seal.gatsbyImageData)) && (
                        <Quote
                            className={styles.info}
                            containerClassName={styles.infoContainer}
                            standalone={true}
                        >
                          {ce.seal && ce.seal.gatsbyImageData &&
                            <span>
                              <GatsbyImage
                                  as="span"
                                  image={remoteAssetUrl(ce.seal.gatsbyImageData)}
                                  alt={ce.seal.alt}
                                  style={{width: '140px'}}
                              />
                            </span>
                          }
                          {ce.sealQuote &&
                            <span>{ce.sealQuote}</span>
                          }
                        </Quote>
                    )}
                  </Products>
                </VisibilityContainer>
            )
          case "DatoCmsMediaText":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <MediaText
                    id={ceId}
                    className={`${ce.imageAside ? " " + styles.textMediaAside : styles.mediaText}${className ? " " + className : ""}`}
                    title={ce.title}
                    htmlText={ce.text}
                    reverse={
                      (ce.alignment || "").toLowerCase() !== process.env.GATSBY_CONTENT_ALIGNMENT_LEFT
                    }
                    image={ce.image}
                    imageBorder={ce.imageBorder}
                    image2={ce.imageAside}
                    image2Border={ce.asideBorder}
                    caption={ce.caption}
                    captionButtonText={ce.captionButtonText}
                    captionButtonUrl={ce.captionButtonUrl}
                    childPosition="text"
                    color={ce.color}
                  >
                    {ce.button1Text && ce.button1Url && (
                      <div className={styles.mediaTextBtn}>
                        <Button theme="block" href={ce.button1Url}>
                          {ce.button1Text}
                        </Button>
                      </div>
                    )}
                    {ce.button2Text && ce.button2Url && (
                      <div className={styles.mediaTextBtn}>
                        <Button theme="block" href={ce.button2Url}>
                          {ce.button2Text}
                        </Button>
                      </div>
                    )}
                  </MediaText>
                </VisibilityContainer>
            )
          case "DatoCmsProductmediaText":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <MediaText
                    id={ceId}
                    className={`${styles.mediaText}${className ? " " + className : ""}`}
                    emphasizeMedia={true}
                    title={ce.title}
                    htmlText={ce.text}
                    reverse={
                      (ce.alignment || "").toLowerCase() !== process.env.GATSBY_CONTENT_ALIGNMENT_LEFT
                    }
                    image={ce.image}
                    imageBorder={false}
                    childPosition="media"
                  >
                    {(ce.captionLabel1 || ce.captionLabel2) && (
                      <Caption>
                        {ce.captionLabel1 && <span>{ce.captionLabel1}</span>}
                        {ce.captionText1 && <em>{ce.captionText1}</em>}

                        {ce.captionLabel2 && <hr />}

                        {ce.captionLabel2 && <span>{ce.captionLabel2}</span>}
                        {ce.captionText2 && <em>{ce.captionText2}</em>}
                      </Caption>
                    )}
                  </MediaText>
                </VisibilityContainer>
            )
          case "DatoCmsTextVideo":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <VideoText
                      id={ceId}
                      className={className}
                      locale={locale}
                      title={ce.title}
                      htmlText={ce.text}
                      videoSrc={ce.video.url}
                      posterSrc={ce.preview.fluid.src}
                      reverse={(ce.alignment || '').toLowerCase() !== process.env.GATSBY_CONTENT_ALIGNMENT_LEFT}
                  />
                </VisibilityContainer>
            );
          case "DatoCmsAppTeaser":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <AppTeaser
                    id={ceId}
                    className={className}
                    screenshot={ce.screenshot}
                    textDecorator={ce.textDecorator}
                    title={ce.title}
                    htmlHeadline={ce.headline}
                    appleUrl={ce.appleUrl}
                    googleUrl={ce.googleUrl}
                    features={[
                      ce.feature1,
                      ce.feature2,
                      ce.feature3,
                      ce.feature4,
                      ce.feature5,
                      ce.feature6,
                      ce.feature7,
                      ce.feature8,
                    ]}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsPriceBox":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <PriceBox
                      id={ceId}
                      className={className}
                      locale={locale}
                      boxInfo={ce.boxInfo}
                      boxHeadline={ce.boxHeadline}
                      htmlHeadline={ce.headline}
                      htmlText={ce.text}
                      main={ce.price}
                      prices={ce.prices}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsPriceTable":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <PriceTable
                    id={ceId}
                    className={className}
                    locale={locale}
                    htmlHeadline={ce.headline}
                    htmlText={ce.text}
                    image={ce.image}
                    color={ce.foregroundColor}
                    showProductname={ce.showProductname}
                    prices={ce.prices}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsPriceChart":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <PriceChart
                    id={ceId}
                    locale={locale}
                    className={className}
                    title={ce.title}
                    htmlText={ce.text}
                    prices={ce.prices}
                    months={ce.months}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsBenefitText":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <BenefitsContainer
                    id={ceId}
                    className={className}
                    textDecorator={ce.textDecorator}
                    textTitle={ce.textTitle}
                    htmlText={ce.text}
                    button1Text={ce.button1Text}
                    button1Url={ce.button1Url}
                    button2Text={ce.button2Text}
                    button2Url={ce.button2Url}
                    benefitHeadline={ce.benefitHeadline}
                    benefit1={ce.benefit1}
                    benefit2={ce.benefit2}
                    benefit3={ce.benefit3}
                    benefit4={ce.benefit4}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsGlobalElement":
            return (
              <GlobalElement
                key={i}
                items={ce.block.content}
                locale={props.locale}
                config={props.config}
                classNames={props.classNames}
                mutators={props.mutators}
              />
            )
          case "DatoCmsNewsletter":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <NewsletterStandalone
                      id={ceId}
                      locale={locale}
                      className={className}
                      text={ce.text}
                      useDefaultText={ce.useDefaultText}
                  />
                </VisibilityContainer>
            );
          case "DatoCmsCallToActionTextButtonNew":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <CtaBigNew
                      id={ceId}
                      className={className}
                      decoration={ce.decoration}
                      title={ce.title}
                      htmlHeadline={ce.headline}
                      text={ce.text}
                      buttonText={ce.buttonText}
                      buttonUrl={ce.buttonUrl}
                      image={ce.image}
                  />
                </VisibilityContainer>
            )
          case "DatoCmsMediaindex":
            return (
                <MediaIndex
                    key={i}
                    locale={locale}
                    id={ceId}
                    className={className}
                    title={ce.title}
                    intro={ce.intro}
                />
            );
          case "DatoCmsTeaserSlider":
            return (
                <TeaserSlider
                    key={i}
                    locale={locale}
                    id={ceId}
                    className={className}
                    {...ce}
                />
            );
          case "DatoCmsTable":
            return (
                <Table
                    key={i}
                    locale={locale}
                    id={ceId}
                    className={className}
                    table={ce.table}
                    headline={ce.headline}
                    text={ce.text}
                />
            );
          case "DatoCmsMediaTextComposition":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <MediaTextComposition
                      id={ceId}
                      locale={locale}
                      className={className}
                      text={ce.text}
                      headline={ce.headline}
                      imageGallery={ce.images}
                      splitBackground={ce.splitBackground}
                  />
                </VisibilityContainer>
            );
          case "DatoCmsImageText":
            return (
                <VisibilityContainer key={i} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <ImageText
                      id={ceId}
                      className={className}
                      title={ce.title}
                      htmlHeadline={ce.headline}
                      headlineClassName={ce.headlineClassName}
                      text={ce.text}
                      image={ce.image}
                      locale={locale}
                      color={ce.color}
                      alignment={ce.alignment}
                  />
                </VisibilityContainer>
            );
          case "DatoCmsFeature":
            return (
                <VisibilityContainer key={ceId} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <Feature
                      id={ceId}
                      locale={locale}
                      className={className}
                      headline={ce.headline}
                      layouts={ce.layouts}
                      icon={ce.icon}
                      imageText={ce.imageText}
                  />
                </VisibilityContainer>
            );
          case "DatoCmsCard":
          case "DatoCmsFaqList":
            return (
              <FaqList
                key={i}
                id={ceId}
                className={className}
                {...ce}
              />
            );
        case "DatoCmsLinkedImage":
          return (
              <LinkedImage
                key={i}
                locale={locale}
                id={ceId}
                className={className}
                {...ce}
              />
          );
          default:
            return (
              <div key={i}>
                unknown content element:{" "}
                {(ce && ce.internal && ce.internal.type) || "unknown type"}
              </div>
            )
          case "DatoCmsSavingPlan":
          case "DatoCmsSavingPlanOverview":
            return (
                <VisibilityContainer key={ceId} payload={ceId} onVisibilityChange={props.onVisibilityChange}>
                  <SavingPlanOverview
                      id={ce.anchor}
                      locale={locale}
                      className={className}
                      headline={ce.headline}
                      semanticOfHeadline={ce.semanticOfHeadline}
                      text={ce.text}
                      image={ce.image}
                      savingPlan={ce.savingPlan}
                  />
                </VisibilityContainer>
            );
        }
      })}
    </>
  )
}
