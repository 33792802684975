import React from "react"

import Text from '../../components/text'

import styles from './component.module.less';

import ContentContainer from "../../components/content-container";
import SavingPlan from "../../components/saving-plan";
import {GatsbyImage} from "gatsby-plugin-image";
import remoteAssetUrl from "../../lib/remote-asset-url";

export default (props) => {
    return (
        <section id={props.id} className={props.className}>
            <ContentContainer className={styles.savingPlanOverview}>
                <div className={styles.savingPlanOverviewHeader}>
                    <Text
                        headline={props.headline}
                        headlineType={props.semanticOfHeadline}
                        className={styles.savingPlanOverviewHeadlineBlock}
                        htmlText={props.text}
                    />
                    <GatsbyImage
                        image={remoteAssetUrl(props.image.gatsbyImageData)}
                        alt={props.alt}
                        className={styles.savingPlanOverviewHeaderImage}
                    />
                </div>
                <div className={styles.savingPlanOverviewSavingsBlock}>
                    {props.savingPlan.map(savingPlan => {
                        return <SavingPlan
                            image={savingPlan.image.gatsbyImageData}
                            name={savingPlan.name}
                            intro={savingPlan.intro}
                            description={savingPlan.description}
                        />
                    })}
                </div>
            </ContentContainer>
        </section>
    );
}
