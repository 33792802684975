import React from "react"
import {graphql, StaticQuery} from "gatsby"

import remoteAssetUrl from "../../lib/remote-asset-url";

import Container from "../../components/content-container";
import Text from "../../components/text";

import styles from './component.module.less'

export default props => (
    <StaticQuery
        query={graphql`
            query {
                allDatoCmsAsset(filter: {copyright: {ne: null}, author: {ne: null}, isImage: {eq: true}}) {
                    nodes {
                        url
                        author
                        copyright
                    }
                }
            }
        `}
        render={data => {

          const assets = data.allDatoCmsAsset.nodes.map(asset => {
            asset.url = remoteAssetUrl(asset.url);
            asset.name = asset.url
                .replace(/\?.*/, '')
                .replace(process.env.GATSBY_WEB_ASSET_REMOTE + '/', '');
            return asset;
          });

          return (
              <section className={styles.mediaIndex}>
                <Container id={props.id} className={`${props.className ? ' ' + props.className : ''}`}>
                  <Text title={props.title} htmlText={props.intro} className={styles.txt}>
                    <ul className={styles.list}>
                      {assets.map((asset, i) => (
                          <li key={i}>
                            <span>
                              <a href={asset.url} target="_blank" rel="noreferrer">{asset.name}</a><br />
                              {asset.copyright} ({asset.author})
                            </span>
                          </li>
                      ))}
                    </ul>
                  </Text>
                </Container>
              </section>
          )
        }}
    />
)
